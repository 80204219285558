import React from 'react'
import { Layout, Col, Row } from 'antd'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import './my-footer.css'

const { Footer } = Layout
interface IProps extends RouteComponentProps { }
interface IState { }
class MyFooter extends React.PureComponent<IProps, IState>{
  computeSelectedKey = () => {
    return [this.props.location.pathname]
  }
  render() {
    return (
    <Footer>
        <Row className="footer-address">
            <Col span={8}>
                <Row className="name">广州智悦</Row>
                <Row className="address">广东省广州市天河区天河科技园建工路15号201松果倾诉</Row>
                <Row className="post">邮编：510665</Row>
            </Col>
            <Col span={8}>
                <Row className="name">广州智悦</Row>
                <Row className="address">广东省广州市天河区天河科技园建工路15号201松果倾诉</Row>
                <Row className="post">邮编：510665</Row>
            </Col>
            <Col span={8}>
                <Row className="name">广州智悦</Row>
                <Row className="address">广东省广州市天河区天河科技园建工路15号201松果倾诉</Row>
                <Row className="post">邮编：510665</Row>
            </Col>
        </Row>
        <Row className="footer-contact">
            <Col span={4}>广州智悦网络科技有限公司©2022</Col>
            <Col span={4}>400-633-6659</Col>
            <Col span={4}>
                <a href="mailto:zyc@51songguo.com">zyc@51songguo.com</a>
            </Col>
        </Row>
      </Footer>
    )
  }
}

export default withRouter(MyFooter)