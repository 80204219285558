import React from 'react'
import { Tabs, Input, Spin, message } from 'antd'
import {
    longUrl2ShortUrl,
    shortUrl2LongUrl,
    queryInfoByIp,
    IQueryInfoByIpResponseContent,
    queryInfoByMobile,
} from '../api/short-url-service'
import {
    createRequestHandler,
} from '../utils/index'
import './entry-view.css'
import { REQUEST_STATUS } from '../utils/constants'
import LOGO from '../assets/logo.png'

const TabPane = Tabs.TabPane
const Search = Input.Search
const REQUEST_STATUS_KEYS = {
    'GET_SHORT_URL': 'getShortUrlStatus',
    'QUERY_INFO_BY_IP': 'queryInfoByIpStatus',
    'GET_LONG_URL': 'getLongUrlStatus',
    'QUERY_INFO_BY_MOBILE': 'queryInfoByMobile',
}
interface IProps { }
interface IState {
    shortUrl: string
    shortUrlInput: string
    longUrl: string
    longUrlInput: string
    getShortUrlStatus: string
    ipInfo: IQueryInfoByIpResponseContent
    mobile: string
    mobileInfo: string
    [key: string]: any
}
export class EntryView extends React.PureComponent<IProps, IState> {
    state = {
        ipInfo: {}
    } as IState
    componentDidMount() {
        this.queryInfoByIp()
    }
    onTabChange = (key: string) => {
        // noop
    }
    onIpChange = (value: string) => {
        const REG = {
            'IPV4': /^((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$/,
            'IPV6': /^([\da-fA-F]{1,4}:){7}[\da-fA-F]{1,4}$/
        }
        if (REG.IPV4.test(value)) {
            this.queryInfoByIp({
                ip: value,
            })
        } else if (REG.IPV6.test(value)) {
            this.queryInfoByIp({
                ip: value
            })
        } else {
            message.error('请输入格式正确的 IP 地址')
        }
    }
    onLongUrlInputChange = (value: string) => {
        const REG = /^https?:\/\//
        if (REG.test(value)) {
            this.setState({
                longUrlInput: value,
            })
            this.getShortUrl({
                url: value,
            })
        } else {
            message.error('网址格式不正确，请以 http:// 或者 https:// 开头')
        }     
    }
    onShortUrlInputChange = (value: string) => {
        this.setState({
            shortUrlInput: value,
        })
        this.getLongUrl({
            shortUrl: value,
        })
    }
    onMobileChange = (mobile: string) => {
        const REG = /^1\d{10}$/
        if (REG.test(mobile)) {
            this.setState({ mobile }, () => {
                this.queryInfoByMobile({
                    mobile
                })
            })
        } else {
            message.error('请输入格式正确的手机号')
        }
    }
    getShortUrl = createRequestHandler({
        context: this,
        statusKey: REQUEST_STATUS_KEYS.GET_SHORT_URL,
        api: longUrl2ShortUrl,
        onSuccess: (shortUrl) => {
            this.setState({
                shortUrl,
            })
            return true
        }
    })
    getLongUrl = createRequestHandler({
        context: this,
        statusKey: REQUEST_STATUS_KEYS.GET_LONG_URL,
        api: shortUrl2LongUrl,
        onSuccess: (longUrl) => {
            this.setState({
                longUrl,
            })
            return true
        }
    })
    queryInfoByIp = createRequestHandler({
        context: this,
        statusKey: REQUEST_STATUS_KEYS.QUERY_INFO_BY_IP,
        api: queryInfoByIp,
        onSuccess: (data) => {
            this.setState({
                ipInfo: data,
            })
            return true
        }
    })
    queryInfoByMobile = createRequestHandler({
        context: this,
        statusKey: REQUEST_STATUS_KEYS.QUERY_INFO_BY_MOBILE,
        api: queryInfoByMobile,
        onSuccess: (address: string) => {
            this.setState({
                mobileInfo: address,
            })
            return true
        }
    })
    computeUrlObj = (url: string) => {
        try {
            const u = new URL(url)
            return {
                address: `${u.origin}${u.pathname}`,
            }
        } catch (e) {
            return {}
        }
    }
    renderViewInCondition = (statusKey: string, successView: any) => {
        switch (this.state[statusKey]) {
            case REQUEST_STATUS.SENDING: return <Spin style={{ marginTop: 24 }} />
            case REQUEST_STATUS.SUCCESS: return successView
            case REQUEST_STATUS.FAILED: return <p>加载出错，请重试</p>
            default: return <p></p>
        }
    }
    render() {
        const {
            shortUrl,
            longUrl,
            longUrlInput,
            ipInfo,
            mobileInfo,
        } = this.state
        return (
            <div
                className="entry-view-layout"
                style={{
                    minHeight: window.innerHeight - 64 * 2,
                    background: '#fff',
                    textAlign: 'center',
                    paddingTop: 120,
                }}>
                {/* LOGO */}
                <div className="logo-wrapper">
                    <img src={LOGO} alt="logo" />
                </div>
                <section className="link-conversions" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    {/* Search Bar */}
                    <Tabs defaultActiveKey="1" onChange={this.onTabChange} style={{ width: 600 }}>
                        <TabPane tab="缩短网址" key="1">
                            <Search
                                placeholder="请输入长地址(以 http:// 或者 https:// 开头)"
                                enterButton="缩短网址"
                                onSearch={this.onLongUrlInputChange} />
                            {
                                this.renderViewInCondition(REQUEST_STATUS_KEYS.GET_SHORT_URL, shortUrl &&
                                    <>
                                        <p className="result-content">
                                            短网址：{shortUrl}
                                        </p>
                                        <p>
                                            原网址：{longUrlInput}
                                        </p>
                                    </>
                                )
                            }
                        </TabPane>
                        <TabPane tab="网址还原" key="2">
                            <Search
                                placeholder="请输入短地址"
                                enterButton="网址还原"
                                onSearch={this.onShortUrlInputChange} />
                            {
                                this.renderViewInCondition(REQUEST_STATUS_KEYS.GET_LONG_URL, longUrl &&
                                    <p className="result-content">
                                        原网址：{longUrl}
                                    </p>
                                )
                            }
                        </TabPane>
                        <TabPane tab="IP 查询" key="3">
                            <Search
                                placeholder="输入 IP 地址查询"
                                enterButton="查询"
                                onSearch={this.onIpChange} />
                            {
                                this.renderViewInCondition(REQUEST_STATUS_KEYS.QUERY_INFO_BY_IP, (
                                    <p className="result-content">
                                        您的IP是 [{ipInfo.ip}] 来自：{ipInfo.country} -> {ipInfo.province} -> {ipInfo.city} {ipInfo.isp}
                                    </p>
                                ))
                            }
                        </TabPane>
                        <TabPane tab="手机归属地查询" key="4">
                            <Search
                                placeholder="输入手机查询归属地"
                                enterButton="查询"
                                onSearch={this.onMobileChange} />
                            {
                                this.renderViewInCondition(REQUEST_STATUS_KEYS.QUERY_INFO_BY_MOBILE, (
                                    <p className="result-content">
                                        您的手机归属地是 {mobileInfo}
                                    </p>
                                ))
                            }
                        </TabPane>
                    </Tabs>
                </section>
            </div>
        )
    }
}