import {IO} from './index'

interface ILongUrl2ShortUrl {
    token?: string
    url: string
}
export const longUrl2ShortUrl = (params: ILongUrl2ShortUrl) => {
    return IO.get<string>('/admin/create', {
        params,
    })
}

interface IShortUrl2LongUrl {
    shortUrl: string
}
export const shortUrl2LongUrl = (params: IShortUrl2LongUrl) => {
    return IO.get<string>('/admin/recovery', {
        params,
    })
}

interface IQueryInfoByIp {
    ip?: string
}
export type IQueryInfoByIpResponseContent = {
    ip: string
    country: string
    province: string
    city: string
    isp: string
}
export const queryInfoByIp = (params: IQueryInfoByIp) => {
    return IO.get<IQueryInfoByIpResponseContent>('/ip/web', {
        params,
    })
}
interface IQueryInfoByMobile {
    mobile: string
}
export const queryInfoByMobile = (params: IQueryInfoByMobile) => {
    return IO.get<string>('/mobile', {
        params,
    })
}