import React from "react";
import { Button, Layout } from "antd";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";

const { Header } = Layout;
const HEADER_STYLE = {
  position: "fixed",
  zIndex: 2,
  width: "100%",
  backgroundColor: "#FFF",
  textAlign: "center",
} as React.CSSProperties;
interface IProps extends RouteComponentProps {}
interface IState {}
class MyHeader extends React.PureComponent<IProps, IState> {
  computeSelectedKey = () => {
    return [this.props.location.pathname];
  };
  onClickContact = () => {
    window.scrollTo(0, document.body.scrollHeight - document.body.clientHeight);
  };
  render() {
    return (
      <Header style={HEADER_STYLE}>
        <Link to="/">主页</Link>
        <Link to="/statistics" style={{ marginLeft: 24 }}>
          数据统计
        </Link>
        <Link to="/api-docs" style={{ marginLeft: 24 }}>
          接口文档
        </Link>
        <Button
          type="link"
          size="small"
          style={{ marginLeft: 24, padding: 0 }}
          onClick={this.onClickContact}
        >
          联系我们
        </Button>
      </Header>
    );
  }
}

export default withRouter(MyHeader);
