import axios, { AxiosRequestConfig, AxiosResponse, AxiosPromise } from 'axios'
import { BASE_URL } from './baseURL'

export interface IResponseData<T = any> {
    responseContent: T
    statusCode: string
    statusMessage: string
}
export type IResponse<T = any> = AxiosPromise<IResponseData<T>>
const instance = axios.create({
    baseURL: BASE_URL,
})
let requestConfigAdapter = (config: AxiosRequestConfig) => config
let requestResponseAdapter = (response: AxiosResponse<IResponseData>) => response
// Add a request interceptor
instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    return requestConfigAdapter(config);
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
})

// Add a response interceptor
instance.interceptors.response.use(function (response) {
    // Do something with response data
    return requestResponseAdapter(response)
}, function (error) {
    // Do something with response error
    let response = error.response
    if (!response) {
        return Promise.reject(error)
    }
    return Promise.reject(error);
})

/**
 * 根据后端的数据格式封装请求方法
 */
export const IO = {
    ...instance,
    request: <T = any>(config: AxiosRequestConfig) => {
        return instance.request<IResponseData<T>>(config)
    },
    get: <T = any>(url: string, config?: AxiosRequestConfig) => {
        return instance.get<IResponseData<T>>(url, config)
    },
    post: <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => {
        return instance.post<IResponseData<T>>(url, data, config)
    },
    put: <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => {
        return instance.put<IResponseData<T>>(url, data, config)
    },
    patch: <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => {
        return instance.patch<IResponseData<T>>(url, data, config)
    },
}
