import {
    message,
} from 'antd'
import {
    IResponse,
} from '../api/index'
import {
    REQUEST_STATUS,
    RESPONSE_CONTENT_CODE,
} from './constants'

export const forOwn = (o: any, cb: (value: any, key: string) => void) => {
    for (let key in o) {
        if (o.hasOwnProperty(key)) {
            cb(o[key], key)
        }
    }
}
export const getStorage = (key: string) => localStorage.getItem(key)
export const setStorage = (o: any) => {
    forOwn(o, (value: string, key: string) => {
        localStorage.setItem(key, value)
    })
}
/**
 * no operation(空函数, 什么都不做)
 */
export const noop = () => { }
export const obj2QueryString = (o: any) => {
    const trim = (s = '') => {
        return String.prototype.trim.bind(s)()
    }
    let a = Object.keys(o).map((key) => {
        return `${key}=${trim(o[key])}`
    })
    let queryString
    if (a.length > 0) {
        queryString = encodeURI(`${a.join('&')}`)
    } else {
        queryString = ''
    }
    return queryString
}
interface ICreateRequestHandlerConfig<T> {
    context: any
    statusKey: string
    api?: (...cbArgs: any[]) => IResponse<T>
    customApiInvoking?: (...cbArgs: any[]) => IResponse<T>
    onSuccess?: (responseContent: T) => boolean
}
export const createRequestHandler = function <T = any>(config: ICreateRequestHandlerConfig<T>) {
    const {
        context,
        api,
        statusKey,
        customApiInvoking,
        onSuccess,
    } = config
    const handler = async (cb: any, ...args: any[]) => {
        if (!context.state) {
            return console.error('context.state is empty')
        }
        try {
            if (context.state[statusKey] === REQUEST_STATUS.SENDING) {
                return
            }
            context.setState({ [statusKey]: REQUEST_STATUS.SENDING })
            const result = await cb(...args)
            const responseContent = result.data.responseContent
            // 根据 onSuccess 的结果决定是否设置 请求成功 状态
            const shouldSetSuccess = onSuccess ? onSuccess(responseContent) : true
            if (shouldSetSuccess) {
                context.setState({ [statusKey]: REQUEST_STATUS.SUCCESS })
            }
            return responseContent
        } catch (e) {
            context.setState({ [statusKey]: REQUEST_STATUS.FAILED })
            message.error(e.message)
        }
    }
    if (customApiInvoking) {
        return async (...args: any[]) => {
            return handler(customApiInvoking, ...args)
        }
    } else if (api) {
        return async (...args: Parameters<typeof api>) => {
            return handler(async (...args: any[]) => {
                const response = await api(...args)
                if (response.data.statusCode === RESPONSE_CONTENT_CODE.SUCCESS) {
                    return response
                } else {
                    throw new Error(response.data.statusMessage)
                }
            }, ...args)
        }
    } else {
        throw new Error('pls set `api` or `customApiInvoking` for doing request')
    }
}
export const obj2array = (o: any): Array<{ key: string, value: any }> => {
    let result: any[] = []
    forOwn(o, (value, key) => {
        result.push({
            key,
            value,
        })
    })
    return result
}
/**
 * 
 * @param patternUrl
 * @param values 
 * @param formater 用于将 key 转换成 patternUrl 中的对应字符串
 */
export const parsePatternUrl = (patternUrl: string, values: any, format: (key: string) => string) => {
    return Object.keys(values).reduce((result, key) => {
        return result.replace(format(key), values[key])
    }, patternUrl)
}
export const getPathname = (patternUrl: string, values: any) => {
    return parsePatternUrl(patternUrl, values, (s) => `:${s}`)
}