export const HTTP_STATUS_CODE = {
    'SUCCESS': 200,
    'NOT_AUTH': 401,
    'FORBIDDEN': 403,
    'NOT_FOUND': 404,
    'SERVICE_UNAVALIABLE': 503,
}
export const RESPONSE_CONTENT_CODE = {
    'SUCCESS': '20000',
    'ERROR': '20400',
}
export const RESPONSE_CONTENT_CHILDREN_CODE = {
    'TOKEN_NOT_VALID': '20104',
}

export const REQUEST_STATUS = {
    'DEFAULT': 'DEFAULT',
    'SENDING': 'SENDING',
    'SUCCESS': 'SUCCESS',
    'FAILED': 'FAILED',
}

export const FORM_MODE = {
    CREATE: 'creating',
    UPDATE: 'updating',
}
export const RECORD_STATUS = {
    ENABLE: 'ENABLE',
    DISABLE: 'DISABLE',
}