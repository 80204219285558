import React from 'react'
import { Spin } from 'antd'

export class Loading extends React.PureComponent {
    render() {
        return (
            <div
                className="statistics-view-layout"
                style={{ background: '#fff', padding: '48px 108px', minHeight: window.innerHeight - 64 * 2 }}>
                <Spin />
            </div>
        )
    }
}