import React from 'react'
import { HashRouter as Router, Route } from 'react-router-dom'
import { Layout } from 'antd'
import { EntryView } from './views/entry-view'
import { StatisticsView } from './views/statistics-view'
import { ApiDocsView } from './views/api-docs-view'
import MyHeader from './components/my-header'
import MyFooter from './components/my-footer'
import './App.css'

const { Content } = Layout
export const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Route component={MyHeader} />
          <Content style={{ marginTop: 64 }}>
            <Route exact path="/" component={EntryView}></Route>
            <Route path="/statistics" component={StatisticsView}></Route>
            <Route path="/api-docs" component={ApiDocsView}/>
          </Content>
          <Route component={MyFooter} />
        </Layout>
      </Router>
    </div>
  )
}

export default App